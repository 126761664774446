import { NTdevUtils } from './modules/ntdev/utils.js';
import { Sharp } from './modules/ntdev/sharp.js';

document.addEventListener('DOMContentLoaded',() => {
    const utils = new NTdevUtils();
    const sharp = new Sharp();

    const loading = document.querySelector('#loading');
    if (loading) {
        setTimeout(() => {
            loading.style.opacity = '0';
            var wow = new WOW({
                boxClass:     'wow',      // animated element css class (default is wow)
                animateClass: 'animated', // animation css class (default is animated)
                offset:       200,          // distance to the element when triggering the animation (default is 0)
                mobile:       true,       // trigger animations on mobile devices (default is true)
                live:         true,       // act on asynchronously loaded content (default is true)
                scrollContainer: null // optional scroll container selector, otherwise use window
            });
            wow.init();
            setTimeout(() => {
                loading.style.display = 'none';
                // WOW
            }, 300);
        }, 1200);
    }

    // Incrusta imagenes SVG
    utils.imgToSVG();

    // Menu responsive
    utils.buttonMenuResponsive('buttonMenu', 'menu', 'active');

    // Estilo menu fijo
    sharp.estiloMenuScroll();

    // scrolling
    document.querySelector('main.main').setAttribute('id', 'main');
    utils.scrollTo('.scrolling', 1000, 0);

    document.querySelectorAll('.has-sub-menu > a').forEach( link => link.addEventListener( 'click', event => event.preventDefault()));

    // Slick Home
        //banner
        jQuery('#banner').slick({
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 1000,
            fade: true
        });

        // testimonios
        jQuery('#slider-testimonios').slick({
            prevArrow: jQuery('#testimonios-arrow-prev'),
            nextArrow: jQuery('#testimonios-arrow-next'),
            dots: true
        });
        
        // novedades
        jQuery('#slider-novedades').slick({
            prevArrow: jQuery('#novedades-arrow-prev'),
            nextArrow: jQuery('#novedades-arrow-next'),
            dots: true
        });
    
    // acordeon
    jQuery('.accordeon .accordeonTitle a').click( function() {
        event.preventDefault();
        if (!jQuery(this).parent().parent().hasClass('active')) {   
            jQuery('.accordeon .accordeonItem').removeClass('active');
            jQuery('.accordeonContent').stop().slideUp();
        }
        jQuery(this).parent().parent().stop().toggleClass('active');
        jQuery(this).parent().next().stop().slideToggle();
    });
})